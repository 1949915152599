import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        actionButtonText: {
            type: [String, Object],
            default: 'Remove'
        },
        description: {
            type: [String, Object],
            default: ''
        },
        actionButtonType: {
            type: String,
            default: 'danger'
        },
        actionButtonIconClass: {
            type: String,
            default: 'fa-solid fa-trash fa-sm'
        }
    },
    setup(_, { emit }) {
        const closePopup = () => {
            emit('close');
        };
        const remove = () => {
            emit('close');
            emit('remove');
        };
        return {
            closePopup,
            remove
        };
    }
});
