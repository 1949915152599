import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        icon: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        name: {
            type: [String, Object],
            default: ''
        },
        description: {
            type: [String, Object],
            default: ''
        },
        danger: {
            type: Boolean,
            default: false
        }
    }
});
